exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-about-js": () => import("./../../../src/pages/old/about.js" /* webpackChunkName: "component---src-pages-old-about-js" */),
  "component---src-pages-old-diabetes-prevention-program-js": () => import("./../../../src/pages/old/diabetes-prevention-program.js" /* webpackChunkName: "component---src-pages-old-diabetes-prevention-program-js" */),
  "component---src-pages-old-diabetes-self-management-training-js": () => import("./../../../src/pages/old/diabetes-self-management-training.js" /* webpackChunkName: "component---src-pages-old-diabetes-self-management-training-js" */),
  "component---src-pages-old-health-care-providers-js": () => import("./../../../src/pages/old/health-care-providers.js" /* webpackChunkName: "component---src-pages-old-health-care-providers-js" */),
  "component---src-pages-old-home-js": () => import("./../../../src/pages/old/home.js" /* webpackChunkName: "component---src-pages-old-home-js" */)
}

