/* global Promise */
/* global Weglot */

import React from 'react';
import { provider } from '~/components/util/provider';
import Wrapper from '~/components/layout/page-wrapper';

import './src/styles/global.css';

export const wrapRootElement = provider;

export const wrapPageElement = ({ element, props }) => {
	return <Wrapper {...props}>{element}</Wrapper>;
};

// const loadScript = uri => {
// 	return new Promise(resolve => {
// 		var tag = document.createElement('script');
// 		tag.src = uri;
// 		tag.async = true;
// 		tag.onload = () => {
// 			resolve();
// 		};
// 		var scriptTag = document.getElementsByTagName('head')[0];
// 		scriptTag.parentNode.insertBefore(tag, scriptTag);
// 	});
// };

// export const onInitialClientRender = () => {
// 	const weglotScript = loadScript('https://cdn.weglot.com/weglot.min.js');
// 	Promise.all([weglotScript]).then(() => {
// 		try {
// 			Weglot.initialize({
// 				api_key: 'wg_5d3267b3a4acbc052cf43b4d038a89b89'
// 			});
// 		// eslint-disable-next-line no-empty
// 		} catch {}
// 	});
// };
